import userStore from "../store/userStore";

export default new class CategoriesController {

    sendEndpoint = async (tgId:any, e:any, title: any, href: any, parentId = 0, itemId: any) => {
        // e.preventDefault();
        // userStore.tele.openLink(href);

        await userStore.writeUserHistory(tgId, `${title}`, href, 'endpoint', parentId, itemId)
    }

    sendTagClick = async (tgid: any, title: any) => {
        userStore.writeUserHistory(tgid, `${title}`, title, 'tag')
    }

    pulseButton = (tgId: any, e: any, prevent = true, title:any = '', content:any = '', type: any = '', parentId:any = 0, itemId: any = -1, itemData: any = false) => {
        let isClick = e.target.getAttribute('data-click') == 'true'?true:false;

        if (isClick && type == 'tag') {
            this.sendTagClick(tgId, title);
            return false
        }

        if (isClick) {
            this.sendEndpoint(tgId, e, title, content, parentId, itemId).then(() => {
                if (itemData && itemData.isFileUrl == '1') {
                    userStore.tele.close();
                }
            });

            if (itemData && itemData.isFileUrl == '1') {
                e.preventDefault();
            }
            
            return false;
        }

        e.preventDefault();
        
        var addDiv  = document.createElement('div'),
            mValue  = Math.max(e.target.clientWidth, e.target.clientHeight),
            rect    = e.target.getBoundingClientRect(),
            sDiv    = addDiv.style,
            px      = 'px';

            sDiv.width  = sDiv.height = mValue + px;
            sDiv.left  = e.clientX - rect.left - (mValue / 2) + px;
            sDiv.top   = e.clientY - rect.top - (mValue / 2) + px;

        addDiv.classList.add('pulse');
        e.target.appendChild(addDiv);

        e.target.setAttribute('data-click', 'true');

        setTimeout(() => {
            e.target.click();
        }, 400);
    }

    autoSizeText = () => {
        const elements = document.querySelectorAll('.category__link-title');
        let body = document.querySelector('body') as HTMLElement;
        let windowWidth = body.offsetWidth;

        if (elements.length < 0) {
            return false;   
        }
      
        for (let el in elements) { 
            const e = elements[el] as HTMLElement | null;
            if (e == null) { continue; }

            const resizeText = () => {
                let font = window.getComputedStyle(e, null).getPropertyValue('font-size'),
                    fontSize = parseInt(font.slice(0, -2));

                if ((windowWidth > 380 && fontSize < 14) || (windowWidth <= 380 && fontSize < 11)) {
                    return false;
                }

                // Получаем высоту родителя
                const parentHeight = e.parentElement!.offsetHeight;
                // Получаем количество строк, которые в него влезут
                // const lines = Math.floor(parentHeight/(fontSize - 1))-1;
                let lines = Math.floor(parentHeight/(fontSize - 1))-1;
                lines = lines>2?2:lines;
                // console.log(lines);
                // Добавляем стили, которые обрезают
                e.style.display = '-webkit-box';
                e.style.webkitLineClamp = lines.toString();
                e.style.webkitBoxOrient = 'vertical';
                e.style.overflow = 'hidden';

                e.style.fontSize = (fontSize - 1) + 'px';
                e.style.lineHeight = (fontSize - 0.5) + 'px';
                e.style.height = (fontSize-0.5 )*lines-1 + 'px';

                return true;
            }

            let i = 0, state = true;;
            while ((e.scrollHeight > e.offsetHeight) && i < 100 && state == true) {
                i++;
                state = resizeText()
            }  
        }
    }

    genTokenUrl = (url: string, token: string) => {
        url = url.replaceAll(' ', '');

        // Если урл не ведёт на доксферу, то return
        const checkDocsfera = url.match(/https:\/\/docsfera.ru/);
        if (checkDocsfera == null || checkDocsfera.length == 0) { return url; }

        if (url[url.length-1] == '/') { return url+`?tgt=${token}`; } // Если просто на / заканчивается ссылка

        const urlArr = url.split('/')
        if (urlArr[urlArr.length-1][0] != '?') {return url+`/?tgt=${token}`; } // Если забыли / и нет параметров

        // разбираем параметры
        // let params = urlArr[urlArr.length-1].replaceAll('?', '').split('&');
        let params = urlArr.pop()?.replaceAll('?', '').split('&');
        if (!params) {return url+`/?tgt=${token}`;}

        params.push(`tgt=${token}`);
        let paramString = `?`+params.join('&');

        
        let tokenUrl = urlArr.join('/') + `/${paramString}`;
        
        return tokenUrl;
    }
}