import { observer } from "mobx-react-lite"
import { FC, useState } from "react"
import { Link, useNavigate } from "react-router-dom";

const SearchWidget:FC<any> = ({ value }: any) => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState(value?value:'');

    const searchSubmitClick = (e:any) => {
        e.preventDefault();
        if (inputValue.length > 2) {
            navigate(`/search/${inputValue}`);
        }
    }

    return (
        <form className="search__container" onSubmit={searchSubmitClick}>
            <div className="search__input-label"><img src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI/SURBVHgBrVVLchJRFD33QUoywx20K5Ad2FSFoCNxoNWzkBWoKwBWoFkBcUSXDoIjqyBV4ArAHfQSmFiGgu7r+zT9BdKd4gxev0+/e967n/MIReD8smRrhSMP7hsPBUFHjNqEag9EDTDXM9s8uXEeYDN4jIz2nVYaHsqejWIn7Adua3BkPWv8bAawFa6uOOBv8rtUpw7nGrJ5RcydaB9jyetqE+Pm6jBBxjgz32D9t4/xu9ym+P+K/J+scGbObqt5kEA490MGd82BAunbdh8FIJzJLYOu9D7yP2P0+muewJnKgGJmjNMt3ItrFEVnVqfaZqFvolz6r/oi6SqhWRgfQzq5sBmgDKQxeShzIEYd5+tuclmEra3XmcZlcjyC25rvkoC48jZN8H7S0Mx61f+JJ4Lh/zY9stIEFYqLiIMVng4vNGKlCZIgUceJIQm2XjxMs5cBsXhpOqookwQqqDp78gEqhThRMgRqElIODGwlcigLZ9I9lCgmBuxH1Udc/aKKB0WhJUP0zIA9jNrjPIF0ExPdGAY06Nl2WIgkq19ArkjjLBpdfFKqGJJ0qOYv9NX3oXNXF860R3S2SCov/HSAzXRqo9SVc3+IpBSrCmVeMgV/9EhliyA7/wgZEt5yEz8ul/sJoqtP+wS+ylZlHuxpHRK8IqaZDnSG5NiTaQEVG1qKSfp652etOXOozNMaFOLDfYcE30U38YNrfG+PCaeEjBmBhrshP1SfC5wS7qV8fDh+S2oPJ5ceA+VemWmq+x8kvuqKpsaOXwAAAABJRU5ErkJggg==" alt="" /></div>
            <input type="text" className="search__input" placeholder="Поиск" value={inputValue} onChange={(e) => {setInputValue(e.target.value)}} />
            {inputValue.length > 2 && <button className="search__flush" onClick={(e) => {
                e.preventDefault();
                setInputValue('')
                navigate('/')
            }}>×</button>}            
            {/* <Link to={`/search/${inputValue}`}><button className="search__button" onClick={searchSubmitClick}>Найти</button></Link> */}
            <button className="search__button" onClick={searchSubmitClick}>Найти</button>
        </form>
    )
}

export default observer(SearchWidget);

