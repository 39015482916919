import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchWidget from "../SearchWidget";
import { observer } from "mobx-react-lite";

const CalcToujeo:FC<any> = ( {tgid}:any ) => {
    const [showResult, setShowResult] = useState<boolean>(false);
    const [selectedResult, setSelectedResult] = useState<number>(0);

    const resultData = [
        {
            mainTitle: 'Если пациент ранее делал 1 инъекцию базального инсулина в сутки',
            mainSubtitle: 'Перевод с базального инсулина, применяемого 1 раз в сутки',
            mainText: 'Стартовая доза Туджео СолоСтар® равна дозе применяемого ранее базального инсулина<sup>1</sup>. Дальнейший подбор дозы можно проводить по разным схемам.',
            data: [
                {
                    image: '/images/calculators/toujeo/1-1.png',
                    text: 'Увеличивайте дозу на 1 ЕД каждый день до достижения целевого уровня<sup>2</sup>.',
                }, 
                {
                    image: '/images/calculators/toujeo/1-2.png',
                    text: 'Увеличивайте дозу на 2 ЕД каждые 3 дня до достижения целевого уровня<sup>3,4</sup>.',
                },
                {
                    image: '/images/calculators/toujeo/1-3.png',
                    text: 'Увеличивайте дозу на 2 ЕД один раз в неделю до достижения целевого уровня<sup>2</sup>.',
                },
            ]
        },
        {
            mainTitle: 'Если пациент ранее делал 2 инъекции базального инсулина в сутки',
            mainSubtitle: 'Перевод с базального инсулина, применяемого 2 раза в сутки',
            mainText: 'Стартовая доза Туджео СолоСтар® на 20% меньше «суточной» дозы применяемого ранее базального инсулина<sup>2</sup>. Дальнейший подбор дозы можно проводить по разным схемам.',
            data: [
                {
                    image: '/images/calculators/toujeo/2-1.png',
                    text: 'Увеличивайте дозу на 2 ЕД каждые 3 дня до достижения целевого уровня, если средний уровень ГПН за предшествующие 3 суток более 5,5 ммоль/л (только при отсутствии уровней глюкозы менее 4,0 ммоль/л)<sup>3,4</sup>.',
                }, 
                {
                    image: '/images/calculators/toujeo/2-2.png',
                    text: 'Увеличивайте дозу на 2 ЕД каждые 3 дня до достижения целевого уровня, если средний уровень ГПН за предшествующие 3 суток более 5,5 ммоль/л (только при отсутствии уровней глюкозы менее 4,0 ммоль/л)<sup>3,4</sup>.',
                },
                {
                    image: '/images/calculators/toujeo/2-3.png',
                    text: 'Увеличивайте дозу на 2 ЕД каждые 3 дня до достижения целевого уровня, если средний уровень ГПН за предшествующие 3 суток более 5,5 ммоль/л (только при отсутствии уровней глюкозы менее 4,0 ммоль/л)<sup>3,4</sup>.',
                },
            ]
        }, 
        {
            mainTitle: 'Если пациент ранее никогда не делал инъекций базального инсулина',
            mainSubtitle: 'Инициация инсулинотерапии с Туджео СолоСтар®',
            mainText: 'Стартовая доза Туджео СолоСтар® рассчитывается как 0,2 единицы на кг массы тела. Дальнейший подбор дозы можно проводить по разным схемам.',
            data: [
                {
                    image: '/images/calculators/toujeo/3-1.png',
                    text: 'Увеличивайте дозу на 2 ЕД каждые 3 дня до достижения целевого уровня, если средний уровень ГПН за предшествующие 3 суток более 5,5 ммоль/л (только при отсутствии уровней глюкозы менее 4,0 ммоль/л)<sup>3,4</sup>.',
                }, 
                {
                    image: '/images/calculators/toujeo/3-2.png',
                    text: 'Увеличивайте дозу на 2 ЕД каждые 3 дня до достижения целевого уровня, если средний уровень ГПН за предшествующие 3 суток более 5,5 ммоль/л (только при отсутствии уровней глюкозы менее 4,0 ммоль/л)<sup>3,4</sup>.',
                },
                {
                    image: '/images/calculators/toujeo/3-3.png',
                    text: 'Увеличивайте дозу на 2 ЕД каждые 3 дня до достижения целевого уровня, если средний уровень ГПН за предшествующие 3 суток более 5,5 ммоль/л (только при отсутствии уровней глюкозы менее 4,0 ммоль/л)<sup>3,4</sup>.',
                },
            ]
        }, 
    ]

    const changeSelectedResult = (id: number) => {
        setSelectedResult(id);
        setShowResult(true);

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 200);
    }

    const ResultContainer:FC<any> = () => {
        const [selectedResultStage, setSelectedResultStage] = useState<number>(-1);

        const hideResultContainer = () => {
            document.querySelector('.calcresult__container')?.classList.add('hide')
            setTimeout(() => {
                setShowResult(false);
            }, 500);
        }

        const setResultStage = (e: React.MouseEvent, id: number) => {
            document.querySelectorAll('.calcresult__button-item').forEach((elem) => {
                elem.classList.remove('active');
            })
            e.currentTarget.classList.add('active');
            setSelectedResultStage(id);
        }

        useEffect(() => {
            if (showResult) {
                setTimeout(() => {
                    document.querySelector('.calcresult__container')?.classList.remove('hide')
                }, 200);
            }
        }, [showResult])
 
        if (!showResult) {
            return <></>
        }

        return <div className="calcresult__container hide">
            <div className="calcresult__content">
                <div className="calcresult__back" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxNy42NjY3TDQuNzQ3NzUgMTBMMTIgMi4zMzMzM0w5Ljc5Mjc5IC05LjY0ODAxZS0wOEwwLjMzMzMzMyAxMEw5Ljc5Mjc5IDIwTDEyIDE3LjY2NjdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" alt="" /> 
                    Вернуться к калькулятору
                </div>
                <button className="calcresult__close" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUwyOSAyOSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTI5IDFMMC45OTk5OTggMjkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=" alt="" />
                </button>
                
                <div className="calcresult__title">{resultData[selectedResult].mainTitle}</div>

                <div className="calcresult__subtitle" dangerouslySetInnerHTML={{__html: resultData[selectedResult].mainSubtitle}}></div>
                <div className="calcresult__text" dangerouslySetInnerHTML={{__html: resultData[selectedResult].mainText}}></div>

                <div className="calcresult__subtitle calcresult__subtitle--sec">
                    Выберите, как часто вы хотите корректировать дозу Туджео СолоСтар®
                </div>
                
                <div className="calcresult__buttons">
                    <button className="calcresult__button-item" onClick={(e) => {
                        setResultStage(e, 0)
                    }}>Каждый день</button>
                    <button className="calcresult__button-item" onClick={(e) => {
                        setResultStage(e, 1)
                    }}>Каждые 3&nbsp;дня</button>
                    <button className="calcresult__button-item" onClick={(e) => {
                        setResultStage(e, 2)
                    }}>1 раз в неделю</button>
                </div>

                {selectedResultStage != -1 &&
                    <>
                        <div className="calcresult__data">
                            <div className="calcresult__data-image"  style={{'backgroundImage': `url("${resultData[selectedResult].data[selectedResultStage].image}")`}}></div>
                            <div className="calcresult__data-info">
                                <div className="calcresult__data-icon" style={{backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTgiIGN5PSIxOCIgcj0iMTgiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xNi40MjY5IDIxLjU3NkwxNS42NTg5IDYuNkgyMC4zOTQ5TDE5LjU5NDkgMjEuNTc2SDE2LjQyNjlaTTE4LjAyNjkgMjkuMjI0QzE3LjI4MDIgMjkuMjI0IDE2LjY2MTUgMjguOTc4NyAxNi4xNzA5IDI4LjQ4OEMxNS42ODAyIDI3Ljk5NzMgMTUuNDM0OSAyNy40MTA3IDE1LjQzNDkgMjYuNzI4QzE1LjQzNDkgMjYuMDQ1MyAxNS42ODAyIDI1LjQ2OTMgMTYuMTcwOSAyNUMxNi42NjE1IDI0LjUwOTMgMTcuMjgwMiAyNC4yNjQgMTguMDI2OSAyNC4yNjRDMTguNzczNSAyNC4yNjQgMTkuMzgxNSAyNC41MDkzIDE5Ljg1MDkgMjVDMjAuMzIwMiAyNS40NjkzIDIwLjU1NDkgMjYuMDQ1MyAyMC41NTQ5IDI2LjcyOEMyMC41NTQ5IDI3LjQxMDcgMjAuMzIwMiAyNy45OTczIDE5Ljg1MDkgMjguNDg4QzE5LjM4MTUgMjguOTc4NyAxOC43NzM1IDI5LjIyNCAxOC4wMjY5IDI5LjIyNFoiIGZpbGw9IiNCMkQzOTMiLz4KPC9zdmc+Cg==")'}}></div>
                                <div className="calcresult__data-text" dangerouslySetInnerHTML={{__html: resultData[selectedResult].data[selectedResultStage].text}}></div>
                            </div>
                        </div>

                        <div className="category__disclaimer category__disclaimer--tj">
                            <div className="disclaimer__sources">
                                <div className="disclaimer__sources-title">Источники</div>
                                <ol className="disclaimer__sources-list">
                                    <li>Общая характеристика лекарственного препарата Туджео СолоСтар® (РУ ЛП-№(003550)-(РГ-RU)) </li> 
                                    <li>Yale JF et al. TITRATION: A Randomized Study to Assess 2 Treatment Algorithms with new Insulin Glargin 300 units/mL. Can J Diabetes. 2017 Oct; 41(5): 478-484.</li>
                                    <li>Davies M et al. Diabetes care 2005 june; 28(6): 1282-1288. </li>
                                    <li>Алгоритмы специализированной медицинской помощи больным сахарным диабетом / Под редакцией И.И. Дедова, М.В. Шестаковой, А.Ю. Майорова. — 11-й выпуск. — М.; 2023.​</li>
                                </ol>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    }


    return <>
        <div className="page-calc page-calc__tj">
            <ResultContainer />

            <div className="category__page">
                <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
                <SearchWidget />

                <h1 className="category__title">
                    <NavLink className="category__back-link" to={`/calculators`}></NavLink>
                    Подбор дозы ТУДЖЕО СОЛОСТАР®
                </h1>

            </div>
            
            <div className="calculator__container calculator__container--tj">
                <div className="calculator__body">
                    <div className="tj__top" style={{'backgroundImage': 'url("/images/calculators/toujeo/main.png")'}}></div>
                    <div className="tj__text">Как правильно подобрать дозу Туджео СолоСтар® у пациентов с разной предшествующей терапией?</div>
                    <button className="tj__button" onClick={() => {
                        changeSelectedResult(0)
                    }}>
                        Если пациент ранее делал <b>1&nbsp;инъекцию</b> базального инсулина в сутки
                    </button>
                    <button className="tj__button" onClick={() => {
                        changeSelectedResult(1)
                    }}>
                        Если пациент ранее делал <b>2&nbsp;инъекции</b> базального инсулина в сутки
                    </button>
                    <button className="tj__button" onClick={() => {
                        changeSelectedResult(2)
                    }}>
                        Если пациент ранее <b>никогда</b> не делал инъекций базального инсулина
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default observer(CalcToujeo);