import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import CategoriesService from "../services/categoriesService";
import userStore from "../store/userStore";
import SearchWidget from "./SearchWidget";

interface SearhItemInterface {
    ID: string,
    NAME: string,
    URL: string,
}

const SearchList:FC<any> = ({ tgid }: any) => {
    const { searchString } = useParams();
    const [searchData, setSearchData] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const sendEndpoint = async (e:any, title: any, href: any) => {
        userStore.writeUserHistory(tgid, `${title}`, href, 'endpoint')
    }

    const pulseButton = (e: any, prevent = true, title:any = '', content:any = '') => {
        let isClick = e.target.getAttribute('data-click') == 'true'?true:false;

        if (isClick) {
            sendEndpoint(e, title, content);
            return false;
        }

        e.preventDefault();
        
        var addDiv  = document.createElement('div'),
            mValue  = Math.max(e.target.clientWidth, e.target.clientHeight),
            rect    = e.target.getBoundingClientRect(),
            sDiv    = addDiv.style,
            px      = 'px';

            sDiv.width  = sDiv.height = mValue + px;
            sDiv.left  = e.clientX - rect.left - (mValue / 2) + px;
            sDiv.top   = e.clientY - rect.top - (mValue / 2) + px;

        addDiv.classList.add('pulse');
        e.target.appendChild(addDiv);

        e.target.setAttribute('data-click', 'true');

        setTimeout(() => {
            e.target.click();
        }, 400);
    }

    const autoSizeText = () => {
        const elements = document.querySelectorAll('.category__link-title');
        let body = document.querySelector('body') as HTMLElement;
        let windowWidth = body.offsetWidth;

        if (elements.length < 0) {
            return false;   
        }
      
        for (let el in elements) { 
            const e = elements[el] as HTMLElement | null;
            if (e == null) { continue; }

            const resizeText = () => {
                let font = window.getComputedStyle(e, null).getPropertyValue('font-size'),
                    fontSize = parseInt(font.slice(0, -2));

                if ((windowWidth > 380 && fontSize < 14) || (windowWidth <= 380 && fontSize < 11)) {
                    return false;
                }

                // Получаем высоту родителя
                const parentHeight = e.parentElement!.offsetHeight;
                // Получаем количество строк, которые в него влезут
                // const lines = Math.floor(parentHeight/(fontSize - 1))-1;
                let lines = Math.floor(parentHeight/(fontSize - 1))-1;
                lines = lines>2?2:lines;
                // console.log(lines);
                // Добавляем стили, которые обрезают
                e.style.display = '-webkit-box';
                e.style.webkitLineClamp = lines.toString();
                e.style.webkitBoxOrient = 'vertical';
                e.style.overflow = 'hidden';

                e.style.fontSize = (fontSize - 1) + 'px';
                e.style.lineHeight = (fontSize - 0.5) + 'px';
                e.style.height = (fontSize-0.5 )*lines-1 + 'px';

                return true;
            }

            let i = 0, state = true;;
            while ((e.scrollHeight > e.offsetHeight) && i < 100 && state == true) {
                i++;
                state = resizeText()
            }  
        }
    }

    const genTokenUrl = (url: string, token: string) => {
        url = url.replaceAll(' ', '');

        // Если урл не ведёт на доксферу, то return
        const checkDocsfera = url.match(/https:\/\/docsfera.ru/);
        if (checkDocsfera == null || checkDocsfera.length == 0) { return url; }

        if (url[url.length-1] == '/') { return url+`?tgt=${token}`; } // Если просто на / заканчивается ссылка

        const urlArr = url.split('/')
        if (urlArr[urlArr.length-1][0] != '?') {return url+`/?tgt=${token}`; } // Если забыли / и нет параметров

        // разбираем параметры
        // let params = urlArr[urlArr.length-1].replaceAll('?', '').split('&');
        let params = urlArr.pop()?.replaceAll('?', '').split('&');
        if (!params) {return url+`/?tgt=${token}`;}

        params.push(`tgt=${token}`);
        let paramString = `?`+params.join('&');

        
        let tokenUrl = urlArr.join('/') + `/${paramString}`;
        
        return tokenUrl;
    }

    const doSearch = async () => {
        const searchResult = await CategoriesService.searchPosts(tgid, searchString);

        if (searchResult.data) {
            setSearchData(searchResult.data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const SearchItem = ({item, i}:any) => {
        let url = item.URL;
        if (item.isDb) {
            url = genTokenUrl(url, userStore.token);
        } else if(userStore.token != '') {
            const _url = new URL(url);
            _url.searchParams.append('tgt', userStore.token);

            item.URL = _url.toJSON();
            // url = _url.toJSON();
        }

        return (
            <li key={i}>
                <div>
                    <a href={item.URL} className="category__link category__link--endpoint" target="_blank" onClick={(e) => {pulseButton(e, false,item.NAME, item.URL);}} >
                        <span className="category__link-title">{item.NAME}</span>
                    </a>
                </div>
            </li>
        )
    }

    const SearchList = () => {
        if (loading) {
            return (
                // <div className="search__loading">Загрузка..</div>
                <div className="content__loader search__loader">
					<div className="loader"></div>	
				</div>
            )
        }

        return (
            <>
                {searchData.length == 0 &&
                    <div className="search__error">По вашему запросу ничего не найдено</div>}

                {searchData.length > 0 && 
                    searchData.map((item: any, i: any) => (
                        !item.deleted && <SearchItem item={item} i={i} key={i} />
                    ))
                    }
            </> 
        )
    }

    useEffect(() => {
        if (searchString?.length == 0) {
            setSearchData([]);
            setLoading(false);
        } else {
            setLoading(true);
            setSearchData([]);
            doSearch();
        }

    }, [searchString])

    useEffect(() => {
        autoSizeText();
    })


    return (
        <ul className="category__list">
            <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
            <SearchWidget value={searchString}/>
            <h1 className="category__title">Результаты поиска:</h1>

            <SearchList />
            
        </ul>
    )
}

export default observer(SearchList);