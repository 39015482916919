import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchWidget from "../SearchWidget";

interface hba1cCheckInterface {
    age: string | '',
    cardio: string | '',
    func: string | '',
    oldAdditional: string | '',
}

const CalcHba1c:FC<any> = ( {tgid}:any ) => {
    const [showResult, setShowResult] = useState<boolean>(false);
    const [checkData, setCheckData] = useState<hba1cCheckInterface>({
        age: '', cardio: '', func: '', oldAdditional: '',
    });

    const setAgeCheck = (value: string) => {
        document.querySelectorAll(('.calculator-body__check-item input[type="radio"]:not([name="age"])')).forEach((item: any) => { item.checked = false })

        setCheckData({
            age: value,
            func: '', oldAdditional: '', cardio: '',
        })
    }

    const clearInput = () => {
        document.querySelectorAll(('.calculator-body__check-item input[type="radio"]')).forEach((item: any) => { item.checked = false })
        setCheckData({
            age: '', func: '', oldAdditional: '', cardio: '',
        })
    }

    const showResultContainer = () => {
        let isValid = true;

        const radios = document.querySelectorAll('.calculator-body__check-item input[type="radio"]');
        radios.forEach((item) => { item.classList.remove('error') })

        const ageRadio = document.querySelectorAll('.calculator-body__check-item input[name="age"]'),
            ageRadioChecked = document.querySelectorAll('.calculator-body__check-item input[name="age"]:checked'), 
            funcRadio = document.querySelectorAll('.calculator-body__check-item input[name="func"]'),
            funcRadioChecked = document.querySelectorAll('.calculator-body__check-item input[name="func"]:checked'),
            cardioRadio = document.querySelectorAll('.calculator-body__check-item input[name="cardio"]'),
            cardioRadioChecked = document.querySelectorAll('.calculator-body__check-item input[name="cardio"]:checked'),
            addRadio =  document.querySelectorAll('.calculator-body__check-item input[name="add"]'),
            addRadioChecked =  document.querySelectorAll('.calculator-body__check-item input[name="add"]:checked');
    
        if (ageRadioChecked.length == 0) {  ageRadio.forEach((item) => { item.classList.add('error') }); isValid = false; }
        if (funcRadio.length > 0 && funcRadioChecked.length == 0) { funcRadio.forEach((item) => { item.classList.add('error') }); isValid = false; }
        if (cardioRadio.length > 0 && cardioRadioChecked.length == 0) { cardioRadio.forEach((item) => { item.classList.add('error') }); isValid = false; }
        if (addRadio.length > 0 && addRadioChecked.length == 0)  { addRadio.forEach((item) => { item.classList.add('error') }); isValid = false; }

        if (isValid) {
            setShowResult(true)
            window.scrollTo(0, 0)
        }
    }

    
    const ResultContainer:FC = () => {
        const [isInfoContentVisible, setIsInfoContentVisible] = useState<boolean>(false);
        const [calcresultData, setCalcresultData] = useState<string>('');

        const hideResultContainer = () => {
            document.querySelector('.calcresult__container')?.classList.add('hide')
            setTimeout(() => {
                setShowResult(false);
            }, 500);
        }

        const switchData = () => {
            if (checkData.age == 'age_low') {
                if (checkData.cardio == 'cardio_yes') {
                    setCalcresultData('<span class="text-red">&#x3C; 7,0 %</span>');
                } else {
                    setCalcresultData('<span class="text-red">&#x3C; 6,5 %</span>');
                }
            }

            else if (checkData.age == 'age_mid') {
                if (checkData.cardio == 'cardio_yes') {
                    setCalcresultData('&#x3C; 7,5 %');
                } else {
                    setCalcresultData('&#x3C; 7,0 %');
                }
            }

            else if (checkData.age == 'age_hi') {
                if (checkData.func == 'func_yes') {
                    if (checkData.oldAdditional == 'add_without') {
                        setCalcresultData('&#x3C; 8,0 %');
                    } else if (checkData.oldAdditional == 'add_dem') {
                        setCalcresultData('&#x3C; 8,5 %');
                    } else if (checkData.oldAdditional == 'add_end') {
                        setCalcresultData('<span class="calcresult__data-text">Избегать гипогликемий и симптомов гипергликемии</span>');
                    }
                } else {
                    if (checkData.cardio == 'cardio_yes') {
                        setCalcresultData('&#x3C; 8,0 %');
                    } else {
                        setCalcresultData('7,5 %');
                    }
                }
            }
        }

        useEffect(() => {
            if (showResult) {
                setTimeout(() => {
                    document.querySelector('.calcresult__container')?.classList.remove('hide')
                }, 200);
            }

            switchData();
            
        }, [showResult])
 
        if (!showResult) {
            return <></>
        }

        return <div className="calcresult__container hide">
            <div className="calcresult__content">
                <div className="calcresult__back" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxNy42NjY3TDQuNzQ3NzUgMTBMMTIgMi4zMzMzM0w5Ljc5Mjc5IC05LjY0ODAxZS0wOEwwLjMzMzMzMyAxMEw5Ljc5Mjc5IDIwTDEyIDE3LjY2NjdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" alt="" /> 
                    Вернуться к калькулятору
                </div>
                <button className="calcresult__close" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUwyOSAyOSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTI5IDFMMC45OTk5OTggMjkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=" alt="" />
                </button>

                <div className="calcresult__title">Результат</div>
                <div className="calcresult__subtitle">Целевое значение гликированного гемоглобина HbA1c</div>
                <div className="calcresult__data" dangerouslySetInnerHTML={{__html: calcresultData}}></div>
            </div>
        </div>
    }

    return <>
        <div className="page-calc page-calc__hba">
            <ResultContainer />

            <div className="category__page">
                <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
                <SearchWidget />

                <h1 className="category__title">
                    <NavLink className="category__back-link" to={`/calculators`}></NavLink>
                    <span>Узнайте индивидуальную цель терапии по HbA1c для своего пациента<sup>1*</sup></span>
                </h1>

            </div>
            
            <div className="calculator__container calculator__container--imt">
                <div className="calculator__body">
                    <div className="calculator__text">Для получения результата введите данные.</div>
                    
                    <div className="calculator-body__title">Возраст</div>
                    <div className="calculator-body__check">
                        <div className="calculator-body__check-item">
                            <input type="radio" name="age" id="age_low" value="age_low" onClick={(e) => setAgeCheck(e.currentTarget.value)} />
                            <label htmlFor="age_low" data-id="age">Молодой<sup>**</sup></label>
                        </div>
                        <div className="calculator-body__check-item">
                            <input type="radio" name="age" id="age_mid" value="age_mid" onClick={(e) => setAgeCheck(e.currentTarget.value)} />
                            <label htmlFor="age_mid" data-id="age">Средний<sup>**</sup></label>
                        </div>
                        <div className="calculator-body__check-item">
                            <input type="radio" name="age" id="age_hi" value="age_hi" onClick={(e) => setAgeCheck(e.currentTarget.value)} />
                            <label htmlFor="age_hi" data-id="age">Пожилой<sup>**</sup></label>
                        </div>
                    </div>

                    {checkData.age === 'age_hi' && 
                        <>
                        <div className="calculator-body__title">Функционально зависимые</div>
                        <div className="calculator-body__check">
                            <div className="calculator-body__check-item">
                                <input type="radio" name="func" id="func_yes" value="func_yes" onClick={(e) => setCheckData({
                                        ...checkData,
                                        func: e.currentTarget.value,
                                    })} />
                                <label htmlFor="func_yes" data-id="func">Да</label>
                            </div>
                            <div className="calculator-body__check-item">
                                <input type="radio" name="func" id="func_no" value="func_no" onClick={(e) => setCheckData({
                                        ...checkData,
                                        func: e.currentTarget.value,
                                    })} />
                                <label htmlFor="func_no" data-id="func">Нет</label>
                            </div>
                        </div>
                        </>
                    }

                    {(checkData.func == 'func_no' || checkData.func == '') &&
                        <>
                        <div className="calculator-body__title">Атеросклеротические сердечно-сосудистые заболевания<sup>#</sup> и/или риск тяжелой гипогликемии<sup>##</sup></div>
                        <div className="calculator-body__check">
                            <div className="calculator-body__check-item">
                                <input type="radio" name="cardio" id="cardio_yes" value="cardio_yes" onClick={(e) => setCheckData({
                                        ...checkData,
                                        cardio: e.currentTarget.value,
                                    })} />
                                <label htmlFor="cardio_yes" data-id="cardio">Да</label>
                            </div>
                            <div className="calculator-body__check-item">
                                <input type="radio" name="cardio" id="cardio_no" value="cardio_no" onClick={(e) => setCheckData({
                                        ...checkData,
                                        cardio: e.currentTarget.value,
                                    })} />
                                <label htmlFor="cardio_no" data-id="cardio">Нет</label>
                            </div>
                        </div>
                        </>
                    }

                    {checkData.func == 'func_yes' &&
                        <>
                             <div className="calculator-body__check">
                            <div className="calculator-body__check-item">
                                <input type="radio" name="add" id="add_without" value="add_without" onClick={(e) => setCheckData({
                                        ...checkData,
                                        oldAdditional: e.currentTarget.value,
                                    })} />
                                <label htmlFor="add_without" data-id="add">Без старческой астении и/или деменции</label>
                            </div>
                            <div className="calculator-body__check-item">
                                <input type="radio" name="add" id="add_dem" value="add_dem" onClick={(e) => setCheckData({
                                        ...checkData,
                                        oldAdditional: e.currentTarget.value,
                                    })} />
                                <label htmlFor="add_dem" data-id="add">Старческая астения и/или деменция</label>
                            </div>
                            <div className="calculator-body__check-item">
                                <input type="radio" name="add" id="add_end" value="add_end" onClick={(e) => setCheckData({
                                        ...checkData,
                                        oldAdditional: e.currentTarget.value,
                                    })} />
                                <label htmlFor="add_end" data-id="add">Завершающий этап жизни</label>
                            </div>
                        </div>
                        </>
                    }

                    <div className="calculator-body__buttons">
                        <button className="calculator-body__button calculator-body__button--black" onClick={showResultContainer}>Рассчитать</button>
                        <button className="calculator-body__button calculator-body__button--white" onClick={clearInput}>Очистить</button>
                    </div>
                </div>
            </div>

            <div className="category__disclaimer">
                <div className="disclaimer__sources">
                    <div className="disclaimer__sources-top">
                        <p><span className="bold">*</span>Данные целевые значения не относятся к детям, подросткам и беременным женщинам. Нормальный уровень в соответствии со стандартами DCCT: до 6%.</p>
                        <p><span className="bold">**</span>По классификации Всемирной организации здравоохранения определены следующие периоды жизни: 18 — 44 лет — молодой возраст, 45 — 59 лет — средний возраст, но составители данных рекомендаций предлагают считать средним возрастом период 45-64 года. Единого определения понятия «пожилой возраст» не существует. Применительно к медицинским рекомендациям в развитых странах наиболее часто определение «пожилой возраст» относится к лицам старше 65 лет.</p>
                        <p><span className="bold">#</span>ИБС (инфаркт миокарда в анамнезе, шунтирование/стентирование коронарных артерий, стенокардия); нарушение мозгового кровообращения в анамнезе; заболевания артерий нижних конечностей (с симптоматикой).</p>
                        <p><span className="bold">##</span>Основными критериями риска тяжелой гипогликемии являются: тяжелая гипогликемия в анамнезе, бессимптомная гипогликемия, большая продолжительность СД, ХБП С3-5, деменция.</p>
                    </div>
                </div>
                <div className="disclaimer__sources">
                    <div className="disclaimer__sources-title">Источники</div>
                    <ol className="disclaimer__sources-list">
                        <li>Адаптировано из: Алгоритмы специализированной медицинской помощи больным сахарным диабетом / Под редакцией И.И. Дедова, М.В. Шестаковой, А.Ю. Майорова . — 11-й выпуск. — М.; 2023.</li> 
                    </ol>
                </div>
                <div className="disclaimer__description">
                    <div className="disclaimer__description-title">Описание идентификатора</div>
                    <div className="disclaimer__description-text">MAT-RU-2302015-1.0-06.2023.</div>
                </div>
            </div>
        </div>
    </>
}

export default observer(CalcHba1c);