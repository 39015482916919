import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import categoriesStore from "../store/categoriesStore";
import userStore from "../store/userStore";
import config from "../config";
import CategoriesController from "../controllers/CategoriesController";

interface Category {
    id: number,
    parent: number,
    title: string,
    image: string,
    isPost: number,
    content: string,
    description: string,
    path: string,
    tags: string,
}

const TagsSearch:FC<any> = ({tgid}: any) => {
    const { from, tag } = useParams();
    let id = from;
    const CategoriesStore = new categoriesStore;
    const [category, setCategory] = useState<Category[]>([]);

    const sendEndpoint = async (e:any, title: any, href: any) => {
        userStore.writeUserHistory(tgid, `${title}`, href, 'endpoint')
    }

    const pulseButton = (e: any, prevent = true, title:any = '', content:any = '') => {
        let isClick = e.target.getAttribute('data-click') == 'true'?true:false;

        if (isClick) {
            sendEndpoint(e, title, content);
            return false;
        }

        e.preventDefault();
        
        var addDiv  = document.createElement('div'),
            mValue  = Math.max(e.target.clientWidth, e.target.clientHeight),
            rect    = e.target.getBoundingClientRect(),
            sDiv    = addDiv.style,
            px      = 'px';

            sDiv.width  = sDiv.height = mValue + px;
            sDiv.left  = e.clientX - rect.left - (mValue / 2) + px;
            sDiv.top   = e.clientY - rect.top - (mValue / 2) + px;

        addDiv.classList.add('pulse');
        e.target.appendChild(addDiv);

        e.target.setAttribute('data-click', 'true');

        setTimeout(() => {
            e.target.click();
        }, 400);
    }

    const autoSizeText = () => {
        const elements = document.querySelectorAll('.category__link-title');
        let body = document.querySelector('body') as HTMLElement;
        let windowWidth = body.offsetWidth;

        if (elements.length < 0) {
            return false;   
        }
      
        for (let el in elements) { 
            const e = elements[el] as HTMLElement | null;
            if (e == null) { continue; }

            const resizeText = () => {
                let font = window.getComputedStyle(e, null).getPropertyValue('font-size'),
                    fontSize = parseInt(font.slice(0, -2));

                if ((windowWidth > 380 && fontSize < 14) || (windowWidth <= 380 && fontSize < 11)) {
                    return false;
                }

                // Получаем высоту родителя
                const parentHeight = e.parentElement!.offsetHeight;
                // Получаем количество строк, которые в него влезут
                // const lines = Math.floor(parentHeight/(fontSize - 1))-1;
                let lines = Math.floor(parentHeight/(fontSize - 1))-1;
                lines = lines>2?2:lines;
                // console.log(lines);
                // Добавляем стили, которые обрезают
                e.style.display = '-webkit-box';
                e.style.webkitLineClamp = lines.toString();
                e.style.webkitBoxOrient = 'vertical';
                e.style.overflow = 'hidden';

                e.style.fontSize = (fontSize - 1) + 'px';
                e.style.lineHeight = (fontSize - 0.5) + 'px';
                e.style.height = (fontSize-0.5 )*lines-1 + 'px';

                return true;
            }

            let i = 0, state = true;;
            while ((e.scrollHeight > e.offsetHeight) && i < 100 && state == true) {
                i++;
                state = resizeText()
            }  
        }
    }

    const genTokenUrl = (url: string, token: string) => {
        url = url.replaceAll(' ', '');

        // Если урл не ведёт на доксферу, то return
        const checkDocsfera = url.match(/https:\/\/docsfera.ru/);
        if (checkDocsfera == null || checkDocsfera.length == 0) { return url; }

        if (url[url.length-1] == '/') { return url+`?tgt=${token}`; } // Если просто на / заканчивается ссылка

        const urlArr = url.split('/')
        if (urlArr[urlArr.length-1][0] != '?') {return url+`/?tgt=${token}`; } // Если забыли / и нет параметров

        // разбираем параметры
        // let params = urlArr[urlArr.length-1].replaceAll('?', '').split('&');
        let params = urlArr.pop()?.replaceAll('?', '').split('&');
        if (!params) {return url+`/?tgt=${token}`;}

        params.push(`tgt=${token}`);
        let paramString = `?`+params.join('&');

        
        let tokenUrl = urlArr.join('/') + `/${paramString}`;
        
        return tokenUrl;
    }

    useEffect(() => {

        CategoriesStore.getCategoryByTag(tag).then((result:any) => {
            setCategory(result.data);
        });
        

    }, [tag])

    useEffect(() => {
        CategoriesController.autoSizeText();
    })

    return (
        <ul className="category__list page__tag">
            <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
            <h1 className="category__title">
                <NavLink className="category__back-link" to={`/${from}`}></NavLink>
                #{ tag }
            </h1>

            {category.length>0?category?.map((item, i) => {
                let url = '';
                if (item.isPost == 1) {
                    // Проверяем, это ссылка на доксферу или нет и имееет ли уже какие-то параметры и подставляем токен
                    url = genTokenUrl(item.content, userStore.token); 
                }

                return (
                    <li key={item.id}>
                        {item.isPost == 0 && 
                            <NavLink className="category__link butt" to={`/${item.id}`} onClick={(e) => pulseButton(e)} >
                                { item.image.length > 0 && 
                                    <img src={`${config.API_URL}${item.image}`} className="category__image" />
                                }
                                <span className="category__link-title">{item.title}</span>
                            </NavLink>
                        }

                        {item.isPost == 1 && 
                            <div>
                                <a href={`${url}`} className="category__link category__link--endpoint" target="_blank" onClick={(e) => {pulseButton(e, false,item.title, url);}}>
                                    { item.image.length > 0 && 
                                        <img src={`${config.API_URL}${item.image}`} className="category__image" />
                                    }
                                    <span className="category__link-title">{item.title}</span>
                                </a>
                                
                                {item.tags != undefined && item.tags != '' && 
                                    <div className="category__tags">
                                        {item.tags.split(',').map((tag, i) => {
                                            tag = tag.trim();
                                            id = id?id:'0';
                                            return (
                                                <NavLink to={`/tag/${id}/${tag}`} key={i} className="tags__item" onClick={(e) => {
                                                    pulseButton(e)
                                                    console.log(id, tag);
                                                }}>{tag[0] == '#'?tag:'#'+tag} </NavLink>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </li>
                )
            }):false}
        </ul>
    )
}
export default observer(TagsSearch);
