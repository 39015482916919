import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchWidget from "../SearchWidget";
import CategoriesService from "../../services/categoriesService";

interface resultTypeInterface {
    image: string,
    title: string,
    subtitle: string,
}

const CalcImt:FC<any> = ( {tgid}:any ) => {
    const [showResult, setShowResult] = useState<boolean>(false);
    const [curResultData, setCurResultData] = useState<resultTypeInterface>();
    const [curCalcResult, setCurCalcResult] = useState<number>(0);

    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');

    const resultData:resultTypeInterface[] = [
        {
            image: 'men-1.png',
            title: 'Дефицит массы тела',
            subtitle: '(18,5 кг/м² и менее)',
        },
        {
            image: 'men-2.png',
            title: 'Нормальная масса тела',
            subtitle: '(18,5 кг/м² - 24,9 кг/м²)',
        },
        {
            image: 'men-3.png',
            title: 'Избыточная масса тела',
            subtitle: '(25,0 кг/м² - 29,9 кг/м²)',
        },
        {
            image: 'men-4.png',
            title: 'Ожирение I степени:',
            subtitle: '(30,0 кг/м² - 34,9 кг/м²)',
        },
        {
            image: 'men-5.png',
            title: 'Ожирение II степени:',
            subtitle: '(35,0 кг/м² - 39,9 кг/м²)',
        },
        {
            image: 'men-6.png',
            title: 'Ожирение III степени',
            subtitle: '(40 кг/м² и более)',
        },
    ]

    const eraseInput = () => {
        setHeight('');
        setWeight('');
    }

    const calculateImt = () => {
        const h = parseInt(height);
        const w = parseInt(weight);

        if (isNaN(h) && isNaN(w)) { return; }

        const r = w/((h/100)*(h/100));
        const result = parseFloat(r.toFixed(1));

        setCurCalcResult(result);

        if (result < 18.5) { setCurResultData(resultData[0]) }
        if (result >= 18.5 && result <= 24.9) { setCurResultData(resultData[1]) }
        if (result >= 25 && result <= 29.9) { setCurResultData(resultData[2]) }
        if (result >= 30 && result <= 34.9) { setCurResultData(resultData[3]) }
        if (result >= 35 && result <= 39.9) { setCurResultData(resultData[4]) }
        if (result >= 40) { setCurResultData(resultData[5]) }

        setShowResult(true);
        window.scrollTo(0, 0)

        CategoriesService.writeCustomLog({
            tgId: tgid, 
            logType: 'calcResult', 
            itemTitle: 'Калькулятор расчета индекса массы тела', 
            calcData: `Рост: ${height}, Вес: ${weight}, ИМТ: ${result}`})
    }
    
    const ResultContainer:FC<{data: resultTypeInterface, calcResult: number}> = ({data, calcResult}) => {
        const [isInfoContentVisible, setIsInfoContentVisible] = useState<boolean>(false);

        const hideResultContainer = () => {
            document.querySelector('.calcresult__container')?.classList.add('hide')
            setTimeout(() => {
                setShowResult(false);
            }, 500);
        }

        useEffect(() => {
            if (showResult) {
                setTimeout(() => {
                    document.querySelector('.calcresult__container')?.classList.remove('hide')
                }, 200);
            }
        }, [showResult])
 
        if (!showResult) {
            return <></>
        }

        return <div className="calcresult__container hide">
            <div className="calcresult__content">
                <div className="calcresult__back" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxNy42NjY3TDQuNzQ3NzUgMTBMMTIgMi4zMzMzM0w5Ljc5Mjc5IC05LjY0ODAxZS0wOEwwLjMzMzMzMyAxMEw5Ljc5Mjc5IDIwTDEyIDE3LjY2NjdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" alt="" /> 
                    Вернуться к калькулятору
                </div>
                <button className="calcresult__close" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUwyOSAyOSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTI5IDFMMC45OTk5OTggMjkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=" alt="" />
                </button>
                <div className="calcresult__title">Результат</div>
                <div className="calcresult__result">ИМТ: <span>{calcResult} (кг/м²)</span></div>
                <div className="calcresult__image" style={{backgroundImage: `url(/images/calculators/imt/${data.image})`}}></div>

                <div className="calcresult__result-data">
                    {data.title} <br />
                    <span>{data.subtitle}</span>
                </div>

                <div className="calcresult__result-info">
                    <div className="info__title">Классификация ожирения по ИМТ, ВОЗ, 1997 г</div>
                    <button className="info__arrow" 
                        onClick={() => setIsInfoContentVisible(!isInfoContentVisible)}
                        style={{backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy42NjY3IDcuNzIyMzVlLTA3TDEwIDcuMjUyMjVMMi4zMzMzMyAxLjAxOTkzZS0wN0wtOS42NDgwMWUtMDggMi4yMDcyMUwxMCAxMS42NjY3TDIwIDIuMjA3MjFMMTcuNjY2NyA3LjcyMjM1ZS0wN1oiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=)'}}></button>
                    <div className="info__content" style={{maxHeight: !isInfoContentVisible?0:'70rem'}}>
                        <div className="info__content-row info__content-row--top">
                            <div className="info__content-item">Индекс массы тела</div>
                            <div className="info__content-item">Соответствие между массой тела и ростом</div>
                        </div>
                        <div className="info__content-row">
                            <div className="info__content-item">&lt;18,5 кг/м²</div>
                            <div className="info__content-item">Дефицит массы тела</div>
                        </div>
                        <div className="info__content-row">
                            <div className="info__content-item">18,5- 24,9 кг/м²</div>
                            <div className="info__content-item">Нормальная масса тела</div>
                        </div>
                        <div className="info__content-row">
                            <div className="info__content-item">25,0-29,9 кг/м²</div>
                            <div className="info__content-item">Избыточная масса тела</div>
                        </div>
                        <div className="info__content-row">
                            <div className="info__content-item">30,0-34,9 кг/м²</div>
                            <div className="info__content-item">Ожирение I степени  </div>
                        </div>
                        <div className="info__content-row">
                            <div className="info__content-item">35,0-39,9 кг/м²</div>
                            <div className="info__content-item">Ожирение II степени</div>
                        </div>
                        <div className="info__content-row">
                            <div className="info__content-item">≥40 кг/м²</div>
                            <div className="info__content-item">Ожирение III степени</div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    }


    return <>
        <div className="page-calc">
            <ResultContainer data={curResultData?curResultData:resultData[0]} calcResult={curCalcResult} />

            <div className="category__page">
                <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
                <SearchWidget />

                <h1 className="category__title">
                    <NavLink className="category__back-link" to={`/calculators`}></NavLink>
                    Калькулятор расчета индекса массы тела
                </h1>

            </div>
            
            <div className="calculator__container calculator__container--imt">
                <div className="calculator__body">
                    <div className="calculator__text">Индекс массы тела (ИМТ) — простое отношение массы тела к росту, часто использующееся для диагностики ожирения и избыточного веса у взрослых. Индекс рассчитывается как отношение массы тела в килограммах к квадрату роста в метрах (кг/м²)1</div>
                    
                    <div className="calculator-body__title">Для получения результата введите данные</div>
                    <div className="calculator-body__subtitle">Рост</div>
                    <div className="calculator-body__input">
                        <input type="number" name={'height'} placeholder="см" value={height} onChange={(e) => setHeight(e.currentTarget.value)} /><span>см</span>
                    </div>
                    <div className="calculator-body__subtitle">Вес</div>
                    <div className="calculator-body__input">
                        <input type="number" name={'weight'} placeholder="кг" value={weight} onChange={(e) => setWeight(e.currentTarget.value)} /><span>кг</span>
                    </div>

                    <div className="calculator-body__buttons">
                        <button className="calculator-body__button calculator-body__button--black" onClick={() => calculateImt()}>Рассчитать</button>
                        <button className="calculator-body__button calculator-body__button--white" onClick={() => eraseInput()}>Очистить</button>
                    </div>
                </div>
            </div>

            <div className="category__disclaimer">
                <div className="disclaimer__sources">
                    <div className="disclaimer__sources-title">Источники</div>
                    <div className="disclaimer__sources-text">
                        Клинические рекомендации "Ожирение",2020 , ID:28 <br />
                        <a href="https://cr.minzdrav.gov.ru/schema/28_2" target="_blank">https://cr.minzdrav.gov.ru/schema/28_2</a>
                    </div>
                </div>
                <div className="disclaimer__description">
                    <div className="disclaimer__description-title">Описание идентификатора</div>
                    <div className="disclaimer__description-text">MAT-RU-2302015-1.0-06.2023.</div>
                </div>
            </div>
        </div>
    </>
}

export default observer(CalcImt);