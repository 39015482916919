import axios from "axios";
import config from "../config";

const API_URL = config.API_URL+'/api';

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
})

export default $api;