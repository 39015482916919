import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import SearchWidget from "./SearchWidget";
import CategoriesController from "../controllers/CategoriesController";
import CategoriesService from "../services/categoriesService";

export const CalcListContainer = ({tgid}: any) => {
    return (
        <>
        <li key={'imt'}>
            <NavLink className="category__link butt" to={`/calculators/imt`} onClick={(e) => {
                if (e.currentTarget.getAttribute('data-click') == 'true') {
                    CategoriesService.writeCustomLog({tgId: tgid, logType: 'gotoCalcPage', itemTitle: 'Калькулятор расчета индекса массы тела'})
                }
                CategoriesController.pulseButton(tgid, e)
                }} >
                {/* { item.image.length > 0 && 
                    <img src={`${config.API_URL}${item.image}`} className="category__image" />
                } */}
                <span className="category__link-title">Калькулятор расчета индекса массы тела</span>
            </NavLink>
        </li>
        <li key={'soliqua'}>
            <NavLink className="category__link butt" to={`/calculators/soliqua`} onClick={(e) => {
                if (e.currentTarget.getAttribute('data-click') == 'true') {
                    CategoriesService.writeCustomLog({tgId: tgid, logType: 'gotoCalcPage', itemTitle: 'Рассчитайте стартовую дозу препарата СОЛИКВА СОЛОСТАР'})
                } 
                CategoriesController.pulseButton(tgid, e)
                }} >
                <span className="category__link-title">Стартовая доза препарата СОЛИКВА СОЛОСТАР®</span>
            </NavLink>
        </li>
        <li key={'toujeo'}>
            <NavLink className="category__link butt" to={`/calculators/toujeo`} onClick={(e) => {
                if (e.currentTarget.getAttribute('data-click') == 'true') {
                    CategoriesService.writeCustomLog({tgId: tgid, logType: 'gotoCalcPage', itemTitle: 'Подбор дозы ТУДЖЕО СОЛОСТАР®'})
                } 
                CategoriesController.pulseButton(tgid, e)
                }} >
                <span className="category__link-title">Подбор дозы ТУДЖЕО СОЛОСТАР®</span>
            </NavLink>
        </li>
        <li key={'hba1c'}>
            <NavLink className="category__link butt" to={`/calculators/hba1c`} onClick={(e) => {
                if (e.currentTarget.getAttribute('data-click') == 'true') {
                    CategoriesService.writeCustomLog({tgId: tgid, logType: 'gotoCalcPage', itemTitle: 'Узнайте индивидуальную цель терапии по HbA1c для своего пациента'})
                } 
                CategoriesController.pulseButton(tgid, e)
                }} >
                <span className="category__link-title">Узнайте индивидуальную цель терапии по HbA1c для своего пациента</span>
            </NavLink>
        </li>
        <li key={'skf'}>
            <NavLink className="category__link butt" to={`/calculators/skf`} onClick={(e) => {
                if (e.currentTarget.getAttribute('data-click') == 'true') {
                    CategoriesService.writeCustomLog({tgId: tgid, logType: 'gotoCalcPage', itemTitle: 'Калькулятор СКФ (формула CKD-EPI)'})
                } 
                CategoriesController.pulseButton(tgid, e)
                }} >
                <span className="category__link-title">Калькулятор СКФ (формула CKD-EPI)</span>
            </NavLink>
        </li>
        </>
    )
}

const CalculatorList:FC<any> = ({tgid}: any) => {
    let { id } = useParams();

    useEffect(() => {
        CategoriesController.autoSizeText();
    })

    return <ul className="category__list">
        <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
        <SearchWidget />

        <h1 className="category__title">
            <NavLink className="category__back-link" to={`/0`}></NavLink>
            Калькуляторы
        </h1>

        <div className="category__title-placeholder"></div>
                    
        <CalcListContainer tgid={tgid} />
    </ul>
}

export default observer(CalculatorList);