import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchWidget from "../SearchWidget";
import CategoriesService from "../../services/categoriesService";

type ResultContainerData = {
    data: {
        type: string,
        title: string,
        imageType: number
    }
}

const CalcSoliqua:FC<any> = ( {tgid}:any ) => {
    const [showResult, setShowResult] = useState<boolean>(false);
    const [resultType, setResultType] = useState<ResultContainerData>();

    const ResultInfo:FC = () => {
        const [isInfoContentVisible, setIsInfoContentVisible] = useState<boolean>(false);

        return (
            <>
            <div className="calcresult__result-info">
                <div className="calcresult__info-top">
                    <div className="info__title">
                        Титрация препарата <br />
                        Соликва СолоСтар®
                    </div>
                    <button className="info__arrow" 
                        onClick={() => setIsInfoContentVisible(!isInfoContentVisible)}
                        style={{backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy42NjY3IDcuNzIyMzVlLTA3TDEwIDcuMjUyMjVMMi4zMzMzMyAxLjAxOTkzZS0wN0wtOS42NDgwMWUtMDggMi4yMDcyMUwxMCAxMS42NjY3TDIwIDIuMjA3MjFMMTcuNjY2NyA3LjcyMjM1ZS0wN1oiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=)'}}></button>
                </div>
                <div className="info__content" style={{maxHeight: !isInfoContentVisible?0:'250rem'}}>
                    <div className="soliqua-result__container">
                        <div className="top-text">Титрация препарата Соликва СолоСтар® проводится по инсулину гларгин 100 ЕД/мл<sup>1,2</sup>.</div>
                        <div className="title">Коррекция каждый день</div>
                        <div className="text">
                            Увеличивайте дозу на 1 единицу каждый день до достижения целевого уровня ГПН, если уровень ГПН более 5,7 ммоль/л 
                            <b> (только при отсутствии уровней глюкозы менее 4,4 ммоль/л)</b>
                        </div>
                        <img src="/images/calculators/soliqua/slq-result-1.png" alt="" />

                        <div className="title">Коррекция каждые три дня</div>
                        <div className="text">
                            Увеличивайте дозу на 2 единицы каждые 3 дня до достижения целевого уровня, если средний уровень ГПН за предшествующие 3 суток более 5,5 ммоль/л 
                            <b> (только при отсутствии уровней глюкозы менее 4,0 ммоль/л)</b>
                        </div>
                        <img src="/images/calculators/soliqua/slq-result-2.png" alt="" />

                        <div className="title">Алгоритм, управляемый врачом (коррекция 1 раз в неделю)</div>
                        <div className="text">
                            Увеличение дозы инсулина осуществляется 
                            <b> только при отсутствии уровней глюкозы крови менее 4,0 ммоль/л</b>
                        </div>
                        <img src="/images/calculators/soliqua/slq-result-3.png" alt="" />

                        <div className="soliqua-result__sources">
                            <div className="soliqua-result__sources-title">ГПН — глюкоза плазмы натощак.</div>
                            <div className="soliqua-result__sources-list-title">Источники</div>
                            <ol className="soliqua-result__sources-list">
                                <li>Davies M. et al. Diabetes Care, 2005; 28(6): 1282-1288.</li>
                                <li>Общая характеристика лекарственного препарата Соликва СолоСтар® (РУ № ЛП-№(000323)-(РГ-RU))</li>
                                <li>Hramiak et al. ADA-2021, Poster95-LB2.</li>
                            </ol>
                        </div>
                    </div>
                </div>  
            </div>
            </>
        )
    }

    const ResultFinal:FC<ResultContainerData> = (props) => {
        const images = [
            '/images/calculators/soliqua/type-1.png',
            '/images/calculators/soliqua/type-2.png',
            '/images/calculators/soliqua/type-3.png',
        ]

        return (
            <>
                <div className="calcresult__title">{props.data.title}</div>
                <div className="calcresult__main-image">
                    <img src={images[props.data.imageType]} />
                </div>
                <ResultInfo />
            </>
        )
    }

    const BasalCalc:FC = () => {
        const [basalCalcState, setBasalCalcState] = useState<string>('select');
        const [basalCalcCheck, setBasalCalcCheck] = useState<string>('');
        const [errorClass, setErrorClass] = useState<string>('');
        const [basalInput, setBasalInput] = useState<number|''>('');
        const [resultImageId, setResultImageId] = useState<number>(0);


        const basalCalcCheckValues = new Map();
        basalCalcCheckValues.set('basal1', {text: 'При переводе с инсулина гларгин 100 ЕД/мл или другого базального инсулина, вводимого 1 раз в сутки.',})
        basalCalcCheckValues.set('basal2', {text: 'При переводе с инсулина гларгин 300 ЕД/мл, вводимого 1 раз в сутки.',})
        basalCalcCheckValues.set('basal3', {text: 'При переводе с другого базального инсулина, вводимого 2 раза в сутки.',})
    

        const validateRadioSelect = () => {
            if (basalCalcCheck === '') {
                setErrorClass('basal-calc__top--error')
                return false;
            }

            setErrorClass('');

            CategoriesService.writeCustomLog({
                tgId: tgid, 
                logType: 'calcResult', 
                itemTitle: 'Рассчитайте стартовую дозу препарата СОЛИКВА СОЛОСТАР', 
                calcData: basalCalcCheckValues.get(basalCalcCheck).text})

            setBasalCalcState('input')
        }

        const validateBasalInput = () => {
            if (typeof basalInput == 'string') {
                setErrorClass('basal-calc__input--error');
                return false;
            }

            if (basalInput == 0 || isNaN(basalInput)) {
                setErrorClass('basal-calc__input--error');
                return false;
            }

            switch (basalCalcCheck) {
                case 'basal1':
                    if (basalInput < 20) {
                        setResultImageId(0);
                    } else if (basalInput >= 20 && basalInput < 30) {
                        setResultImageId(1);
                    } else if (basalInput >= 30) {
                        setResultImageId(2);
                    }
                    break;
                case 'basal3':
                case 'basal2':
                    if (basalInput < 25) {
                        setResultImageId(0);
                    } else if (basalInput >= 25 && basalInput < 38) {
                        setResultImageId(1);
                    } else if (basalInput >= 38) {
                        setResultImageId(2);
                    }
                    break;
            }

            CategoriesService.writeCustomLog({
                tgId: tgid, 
                logType: 'calcResult', 
                itemTitle: 'Рассчитайте стартовую дозу препарата СОЛИКВА СОЛОСТАР', 
                calcData: `Ввод: ${basalInput}`})

            setErrorClass('');
            setBasalCalcState('result')
        }

        if (basalCalcState == 'select') {
            return <>
                <div className="basal-calc">
                    <div className={`basal-calc__top ${errorClass}`}>
                        <div className="basal-calc__title">Какой инсулин получает ваш пациент?</div>
                        <div className="basal-calc__subtitle">Отметьте подходящий вариант ответа</div>

                        <div className="basal-calc__check-list">
                            <div className="basal-calc__check-item">
                                <input type="radio" id="basal1" name="basal" value="basal1" onChange={(e) => {
                                    setBasalCalcCheck(e.currentTarget.value)
                                    setErrorClass('');
                                }} />
                                <label htmlFor="basal1"><span>Инсулин гларгин 100 ЕД/мл или другой базальный инсулин, вводимый 1 раз в сутки</span></label>
                            </div>
                            <div className="basal-calc__check-item">
                                <input type="radio" id="basal2" name="basal" value="basal2" onChange={(e) => {
                                    setBasalCalcCheck(e.currentTarget.value)
                                    setErrorClass('');
                                }} />
                                <label htmlFor="basal2"><span>Инсулин гларгин 300 ЕД/мл, вводимый 1 раз в сутки</span></label>
                            </div>
                            <div className="basal-calc__check-item">
                                <input type="radio" id="basal3" name="basal" value="basal3" onChange={(e) => {
                                    setBasalCalcCheck(e.currentTarget.value)
                                    setErrorClass('');
                                }} />
                                <label htmlFor="basal3"><span>Другой базальный инсулин, вводимый 2 раза в сутки</span></label>
                            </div>
                        </div>
                    </div>
                    <div className="basal-calc__bottom">
                        <button className="basal-calc__btn" onClick={() => {validateRadioSelect()}}>Продолжить</button>
                    </div>
                </div>
            </>
        }

        if (basalCalcState == 'input') {
            return <>
                <div className="basal-calc">
                    <div className={`basal-calc__top ${errorClass}`}>
                        <div className="basal-calc__title">Введите суточную дозу инсулина, которую получает пациент</div>
                        <div className="basal-calc__subtitle">{basalCalcCheck!=''?basalCalcCheckValues.get(basalCalcCheck).text:''}</div>

                        <div className="basal-calc__input-container">
                            <div className="basal-calc__input-title">Суточная доза инсулина</div>
                            <div className="basal-calc__input-content">
                                <input type="number" id="basal-calc__input" className={errorClass} min={0} value={basalInput} onChange={(e) => {
                                    setBasalInput(parseInt(e.target.value))
                                }} />
                                <label htmlFor="basal-calc__input">ЕД</label>
                            </div>
                        </div>
                    </div>
                    <div className="basal-calc__bottom">
                        <button className="basal-calc__btn" onClick={() => {validateBasalInput()}}>Продолжить</button>
                    </div>
                </div>
            </>
        }

        if (basalCalcState == 'result') {
            return <>
                <ResultFinal data={{
                    title: 'Результат',
                    type: 'basal',
                    imageType: resultImageId
                }}/>
                {/* <button onClick={() => {setBasalCalcState('select')}}>go</button> */}
            </>
        }

        return (
            <></>
        )
    }
 
    const ResultContainer:FC<any> = () => {

        const hideResultContainer = () => {
            document.querySelector('.calcresult__container')?.classList.add('hide')
            setTimeout(() => {
                setShowResult(false);
            }, 500);
        }

        useEffect(() => {
            if (showResult) {
                setTimeout(() => {
                    document.querySelector('.calcresult__container')?.classList.remove('hide')
                }, 200);
            }
        }, [showResult])
 
        if (!showResult || !resultType || resultType == undefined) {
            return <></>
        }

        return <div className="calcresult__container hide">
            <div className="calcresult__content">
                <div className="calcresult__back" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxNy42NjY3TDQuNzQ3NzUgMTBMMTIgMi4zMzMzM0w5Ljc5Mjc5IC05LjY0ODAxZS0wOEwwLjMzMzMzMyAxMEw5Ljc5Mjc5IDIwTDEyIDE3LjY2NjdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" alt="" /> 
                    Вернуться к калькулятору
                </div>
                <button className="calcresult__close" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUwyOSAyOSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTI5IDFMMC45OTk5OTggMjkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=" alt="" />
                </button>
                
                {(() => {
                    switch (resultType.data.type) {
                        case 'pssp': 
                            return <ResultFinal data={resultType.data}/>;
                        default:
                            return <BasalCalc />
                    }
                })()}
            </div>
        </div>
    }

    const showResultContainer = (resultData: ResultContainerData) => {
        window.scrollTo(0, 0)
        setResultType(resultData);
        setShowResult(true);
    }


    return (
        <>
        <div className="page-calc page-calc__soliqua">
            <ResultContainer />

            <div className="category__page">
                <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
                <SearchWidget />

                <h1 className="category__title">
                    <NavLink className="category__back-link" to={`/calculators`}></NavLink>
                    <span>Рассчитайте стартовую дозу препарата СОЛИКВА СОЛОСТАР&nbsp;®<sup>1</sup></span>
                </h1>

            </div>
            
            <div className="calculator__container calculator__container--soliqua">
                <div className="calculator__body">
                    <img src="/images/calculators/soliqua/soliqua-1.png" alt="" className="calculator__img" />
                    <div className="calculator__text">Препарат Соликва СолоСтар® имеет всего 3 стартовые дозы: 10, 20 и 30 единиц (в зависимости от предшествующей сахароснижающей терапии).</div>
                    <div className="calculator-body__title">Выберите, что получает ваш пациент:</div>

                    <div className="calculator-body__buttons">
                        <button className="calculator-body__button calculator-body__button--black" onClick={() => {
                            showResultContainer({
                                data: {
                                    type: 'pssp', 
                                    title: 'Для пациентов, ранее не получавших инсулин', 
                                    imageType: 0
                                }
                            });

                            CategoriesService.writeCustomLog({
                                tgId: tgid, 
                                logType: 'calcResult', 
                                itemTitle: 'Рассчитайте стартовую дозу препарата СОЛИКВА СОЛОСТАР', 
                                calcData: `Для пациентов, ранее не получавших инсулин`})

                        }}>Только ПССП</button>

                        <button className="calculator-body__button calculator-body__button--black" onClick={() => {
                            showResultContainer({
                                data: {
                                    type: 'basal', 
                                    title: '', 
                                    imageType: 0
                                }
                            })

                            CategoriesService.writeCustomLog({
                                tgId: tgid, 
                                logType: 'calcResult', 
                                itemTitle: 'Рассчитайте стартовую дозу препарата СОЛИКВА СОЛОСТАР', 
                                calcData: `Базальный инсулин`})
                        }}>Базальный инсулин</button>
                    </div>

                    <div className="calculator__text calculator__text--bottom">Отметьте подходящий вариант ответа</div>
                </div>
            </div>

            <div className="category__disclaimer category__disclaimer--soliqua">
                <div className="disclaimer__sources">
                    <div className="disclaimer__sources-top">
                        ГПН — глюкоза плазмы натощак;
                        <br />
                        ППГ — постпрандиальная гликемия.
                    </div>
                    <div className="disclaimer__sources-title">Источники</div>
                    <ol className="disclaimer__sources-list">
                        <li>Общая характеристика лекарственного препарата Соликва СолоСтар® (РУ № ЛП-№(000323)-(РГ-RU)).</li> 
                        <li>Rosenstock J. et al. Diabetes Care, 2016 Nov; 39(11): 2026-2035.</li>
                        <li>Ferrannini E. L. Fixed-Ratio Combination of Insulin Glargine Plus Lixisenatide (iGlarLixi) Improves ß-Cell Function in People With Type 2 Diabetes EASD, 2021, Short Oral 501.</li>
                    </ol>
                </div>
                <div className="disclaimer__description">
                    <div className="disclaimer__description-text">MAT-RU-2302015-1.0-06.2023.</div>
                </div>
            </div>
        </div>
        </>
    )
}

export default observer(CalcSoliqua);