import categoriesService from "../services/categoriesService";

export default class categoriesStore {
    async getCategory(id:any, tgid: any) {
        try {
            return (await categoriesService.getCategory(id, tgid)).data;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getCurrentCategory(id:any, tg_id:number) {
        try {
            return (await categoriesService.getCategoryInfo(id, tg_id)).data;
        } catch (e) {
            console.log(e)
            return false;
        }
    }

    async getCategoryByTag(tag:any) {
        try {
            return (await categoriesService.getCategoryByTag(tag))

        } catch (e) {
            console.log(e);
            return false;
        }
    }
}