import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchWidget from "../SearchWidget";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

interface toggleContentInterface {
    diag: boolean,
    classf: boolean,
    level: boolean,
    combi: boolean,
}

interface calcDataInterface {
    sex: 'male' | 'female' | false,
    race: 'other' | 'negro' | false,
    age: number | '',
    createnin: number | '',
    searchType: 'mkml' | 'mgdl',
}

interface resultDataInterface {
    stage: string,
    description: string,
    value: number,
}

const CalcSkf:FC<any> = ( {tgid}:any ) => {
    const [resultData, setResultData] = useState<resultDataInterface>({
        value: 0,
        stage: '',
        description: '',
    })
    const [showResult, setShowResult] = useState<boolean>(false);
    const [calcData, setCalcData] = useState<calcDataInterface>({
        sex: false,
        race: false,
        age: '',
        createnin: '',
        searchType: 'mkml',
    })

    const showResultContainer = () => {
        let isValid = true;

        if (isValid) {
            setShowResult(true)
            window.scrollTo(0, 0)
        }
    }

    const eraseInput = () => {
        setCalcData({
            sex: false,
            race: false,
            age: '',
            createnin: '',
            searchType: 'mkml',
        });

        document.querySelectorAll('input[type="radio"]:checked').forEach((item : any ) => {item.checked = false})
    }

    const checkErrors = () => {
        let isValid = true;
        document.querySelectorAll('.error').forEach((item:any) => {item.classList.remove('error')})

        if (!calcData.sex) {
            document.querySelectorAll('input[name="sex"]').forEach((item:any) => {item.classList.add('error')})
            isValid = false;
        }

        if (!calcData.race) {
            document.querySelectorAll('input[name="race"]').forEach((item:any) => {item.classList.add('error')})
            isValid = false;
        }

        if (calcData.age === '' || calcData.age === 0) {
            document.querySelectorAll('input[name="age"]').forEach((item:any) => {item.classList.add('error')})
            isValid = false;
        }

        if (calcData.createnin === '' || calcData.createnin === 0) {
            document.querySelectorAll('input[name="createnin"]').forEach((item:any) => {item.classList.add('error')})
            isValid = false;
        }

        return isValid;
    }

    const calculateResult = () => {
        if (!checkErrors() || calcData.createnin === '' || calcData.age === '') { return; }

        let createnin = calcData.createnin;
        if (calcData.searchType === 'mkml') {
            createnin = ( createnin / 88.4 )
        }

        let varI = -1;
        let varJ = -1;
        let varK = -1;
        let varA = -1;

        if ( calcData.sex === 'male' ) {
            varI = 1;
            varK = 0.9;
            varA = -0.411;
        } else {
            varI = 1.018;
            varK = 0.7;
            varA = -0.329;
        }

        if ( calcData.race === 'negro' ) {
            varJ = 1.159;
        } else {
            varJ = 1;
        }

        let minBlockValue = Math.min( (createnin / varK) , 1);
        minBlockValue = Math.pow(minBlockValue, varA);
        let maxBlockValue = Math.max( (createnin / varK) , 1);
        maxBlockValue = Math.pow(maxBlockValue, -1.209);
        let ageBlockValue = Math.pow(0.993, calcData.age);
        let ckfValue = 141 * varI * varJ * minBlockValue * maxBlockValue * ageBlockValue;
        ckfValue = Math.round(ckfValue);

        let hbpStage = '';
        let hbpDesc = '';

        if ( ckfValue < 15 ) {
            hbpStage = '5';
            hbpDesc = 'Терминальная почечная недостаточность (Если пациент получает заместительную почечную терапию следует указывать ее вид – диализ (Д) или трансплантация (Т))'
        }

        if ( ckfValue >= 15 && ckfValue <= 29 ) {
            hbpStage = 'С4';
            hbpDesc = 'Резко сниженная СКФ'
        }

        if ( ckfValue >= 30 && ckfValue <= 44 ) {
            hbpStage = 'С3б';
            hbpDesc = 'Существенно сниженная СКФ'
        }

        if ( ckfValue >= 45 && ckfValue <= 59 ) {
            hbpStage = 'С3а';
            hbpDesc = 'Умеренное снижение СКФ'
        }

        if ( ckfValue >= 60 && ckfValue <= 89 ) {
            hbpStage = 'С2';
            hbpDesc = 'Незначительно сниженная СКФ (При отсутствии признаков повреждения почек не удовлетворяет критериям ХБП)'
        }

        if ( ckfValue >= 90 ) {
            hbpStage = 'С1';
            hbpDesc = 'Высокая или оптимальная СКФ (При отсутствии признаков повреждения почек не удовлетворяет критериям ХБП)'
        }

        setResultData({
            value: ckfValue,
            stage: hbpStage,
            description: hbpDesc,
        })

        setShowResult(true);
    }

    
    const ResultContainer:FC = () => {
        const [toggleContentData, setToggleContentData] = useState<toggleContentInterface>({
            diag: false,
            classf: false,
            level: false,
            combi: false,
        });

        const hideResultContainer = () => {
            document.querySelector('.calcresult__container')?.classList.add('hide')
            setTimeout(() => {
                setShowResult(false);
            }, 500);
        }

        useEffect(() => {
            if (showResult) {
                setTimeout(() => {
                    document.querySelector('.calcresult__container')?.classList.remove('hide')
                }, 200);
            }
            
        }, [showResult])
 
        if (!showResult) {
            return <></>
        }

        return <div className="calcresult__container hide">
            <div className="calcresult__content">
                <div className="calcresult__back" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxNy42NjY3TDQuNzQ3NzUgMTBMMTIgMi4zMzMzM0w5Ljc5Mjc5IC05LjY0ODAxZS0wOEwwLjMzMzMzMyAxMEw5Ljc5Mjc5IDIwTDEyIDE3LjY2NjdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" alt="" /> 
                    Вернуться к калькулятору
                </div>
                <button className="calcresult__close" onClick={() => {hideResultContainer()}}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUwyOSAyOSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTI5IDFMMC45OTk5OTggMjkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=" alt="" />
                </button>

                <div className="calcresult__title">Результат</div>
                <div className="calcresult__subtitle">Значение СКФ</div>
                <div className="calcresult__num">{resultData.value}</div>
                <div className="calcresult__num-sub">мл/мин 1,73м<sup>2</sup></div>
                <div className="calcresult__stage-title">Стадия</div>
                <div className="calcresult__stage">{resultData.stage}</div>


                <div className="calcresult__description">
                    <div className="calcresult__description-title">Описание</div>
                    <div className="calcresult__description-text">{resultData.description}</div>
                </div>

                <button className={`calcresult__button calcresult__button--diag ${toggleContentData?.diag?'active':''}`} onClick={() => {
                    setToggleContentData({
                        ...toggleContentData,
                        diag: !toggleContentData.diag
                    })
                }}>Диагностические критерии ХБП <sup>* 2,3,4</sup></button>
                <div className="calcresult__diag" style={{maxHeight: toggleContentData.diag?'10000px':'0'}}>
                    <div className="calcresult__diag-top-text">В случае сохранной или повышенной СКФ, а также у больных с ее начальным снижением (60≤СКФ≤90 мл/мин/1,73 м <sup>2</sup>) для диагноза ХБП необходимо присутствие признаков повреждения почек.</div>
                    <div className="calcresult__diag-table">
                        <div className="calcresult__diag-table-top">Снижение СКФ</div>
                        <div className="calcresult__diag-sub">СКФ &#60;60 мл/мин/1,73м2 (категории СКФ 3а-5)</div>
                        <div className="calcresult__diag-hilight">Маркеры почечного повреждения (один или больше)</div>
                        <div className="calcresult__diag-text">
                            <p>Альбуминурия [скорость экскреции альбумина с мочой] ≥30 мг/24 час, отношение Ал/Кр мочи ≥30 мг/г (≥3 мг/ммоль)</p>
                            <p>Изменения мочевого осадка</p>
                            <p>Канальцевая дисфункция</p>
                            <p>Гистологические изменения</p>
                            <p>Структурные изменения при визуализирующих методах исследования</p>
                            <p>Трансплантация почки в анамнезе</p>
                        </div>
                    </div>
                    <div className="calcresult__diag-info">
                        <p>* При отсутствии признаков повреждения почек категории СКФ С1 и С2 не удовлетворяют критериям ХБП</p>
                        <p>** Если пациент получает заместительную почечную терапию следует указывать ее вид – диализ (Д) или трансплантация (Т)</p>
                        <p>СКФ — скорость клубочковой фильтрации</p>
                        <p>ХБП — хроническая болезнь почек.</p>
                        <p>Ар/Кр — отношение альбумин/креатинин.</p> 
                    </div>
                </div>

                <button className={`calcresult__button calcresult__button--classf ${toggleContentData?.classf?'active':''}`} onClick={() => {
                    setToggleContentData({
                        ...toggleContentData,
                        classf: !toggleContentData.classf
                    })
                }}>Классификация ХБП по уровню СКФ <sup>2,3,4</sup></button>
                <div className="calcresult__classf"  style={{maxHeight: toggleContentData.classf?'10000px':'0'}}>
                    <div className="calcresult__classf-table">
                        <div className="calcresult__classf-table-row calcresult__classf-table--header">
                            <div className="calcresult__classf-table-col">Стадия</div>
                            <div className="calcresult__classf-table-col">Уровень СКФ <br />(мл/мин/1,73м2)</div>
                            <div className="calcresult__classf-table-col">Описание</div>
                        </div>

                        <div className="calcresult__classf-table-row">
                            <div className="calcresult__classf-table-col">С1</div>
                            <div className="calcresult__classf-table-col">&#62;90</div>
                            <div className="calcresult__classf-table-col">Высокая или оптимальная СКФ*</div>
                        </div>
                        <div className="calcresult__classf-table-row">
                            <div className="calcresult__classf-table-col">С2</div>
                            <div className="calcresult__classf-table-col">60-89</div>
                            <div className="calcresult__classf-table-col">Незначительно сниженная СКФ*</div>
                        </div>
                        <div className="calcresult__classf-table-row">
                            <div className="calcresult__classf-table-col">С3 <span className="bot">а</span></div>
                            <div className="calcresult__classf-table-col">45-59</div>
                            <div className="calcresult__classf-table-col">Умеренно сниженная СКФ</div>
                        </div>
                        <div className="calcresult__classf-table-row">
                            <div className="calcresult__classf-table-col">С3 <span className="bot">б</span></div>
                            <div className="calcresult__classf-table-col">30-44</div>
                            <div className="calcresult__classf-table-col">Существенно сниженная СКФ</div>
                        </div>
                        <div className="calcresult__classf-table-row">
                            <div className="calcresult__classf-table-col">C4</div>
                            <div className="calcresult__classf-table-col">15-29</div>
                            <div className="calcresult__classf-table-col">Резко сниженная СКФ</div>
                        </div>
                        <div className="calcresult__classf-table-row">
                            <div className="calcresult__classf-table-col">C5</div>
                            <div className="calcresult__classf-table-col">&#60;15</div>
                            <div className="calcresult__classf-table-col">Терминальная почечная недостаточность**</div>
                        </div>
                    </div>
                    <div className="calcresult__classf-text">
                        <p>* При отсутствии признаков повреждения почек категории СКФ С1 и С2 не удовлетворяют критериям ХБП</p>
                        <p>** Если пациент получает заместительную почечную терапию следует указывать ее вид – диализ (Д) или трансплантация (Т)</p>
                        <p>СКФ — скорость клубочковой фильтрации</p>
                        <p>ХБП — хроническая болезнь почек.</p>
                    </div>
                </div>

                <button  className={`calcresult__button calcresult__button--level ${toggleContentData?.level?'active':''}`} onClick={() => {
                    setToggleContentData({
                        ...toggleContentData,
                        level: !toggleContentData.level
                    })
                }}>Индексация ХБП по уровню альбуминурии <sup>2,4</sup></button>
                <div className="calcresult__level"  style={{maxHeight: toggleContentData.level?'10000px':'0'}}>
                    <div className="calcresult__bigimage">
                        <Zoom>
                            <img src="/images/calculators/skf/1.png" alt="" className="calcresult__bigimage-img" />
                        </Zoom>
                    </div>
                </div>

                <button  className={`calcresult__button calcresult__button--combi ${toggleContentData?.combi?'active':''}`} onClick={() => {
                    setToggleContentData({
                        ...toggleContentData,
                        combi: !toggleContentData.combi
                    })
                }}>Комбинированный риск прогрессирования ХБП и развития ССО в зависимости от степени снижения СКФ и выраженности альбуминурии <sup>2,4</sup></button>
                <div className="calcresult__combi" style={{maxHeight: toggleContentData.combi?'10000px':'0'}}>
                    <div className="calcresult__bigimage">
                        <Zoom>
                            <img src="/images/calculators/skf/2.png" alt="" className="calcresult__bigimage-img" />
                        </Zoom>
                    </div>

                    <div className="calcresult__combi-text">
                        <p>Существуют ряд ситуаций, в которых использование расчетных методов оценки СКФ некорректно:</p>
                        <ul>
                            <li>нестандартные размеры тела (пациенты с ампутацией конечностей, бодибилдеры);</li>
                            <li>выраженные истощение и ожирение (ИМТ&#60;15 и &#62;40кг/м2);</li>
                            <li>беременность;</li>
                            <li>заболевания скелетной мускулатуры (миодистрофии);</li>
                            <li>параплегия и квадриплегия;</li>
                            <li>вегетарианская диета;</li>
                            <li>быстрое снижение функции почек (острый и быстро прогрессирующий гломерулонефрит, острое почечное повреждение);</li>
                            <li>необходимость назначения токсичных препаратов, выводимых почками (например химиотерапия) — для определения их безопасной дозы; при решении вопроса о начале заместительной почечной терапии;</li>
                            <li>больные с почечным трансплантатом. </li>
                        </ul>
                        <p>В таких обстоятельствах необходимо воспользоваться, как минимум, стандартным измерением клиренса эндогенного креатинина (проба Реберга–Тареева) или другими клиренсовыми методами (обычно плазматическими или почечными клиренсами комплексонов или рентгеновских контрастов)<sup>3</sup></p>
                    </div>
                </div>

                <div className="calcresult__abbr">
                <div className="calcresult__abbr-title">Список сокращений</div>
                    <p>СКФ — скорость клубочковой фильтрации</p>
                    <p>СД2 — сахарный диабет 2 типа</p>
                    <p>ХБП — хроническая болезнь почек</p>
                    <p>HbA1c — гликированный гемоглобин</p>
                    <p>ДИ — доверительный интервал</p>
                    <p>ОЧ — отношение частот</p>
                    <p>МНК — средняя величина, вычисленная методом наименьших квадратов (least square mean)</p>
                    <p>рСКФ — расчетная скорость клубочковой фильтрации</p>
                    <p>ССО — сердечно-сосудистые осложнения</p>
                    <p>ИМТ — индекс массы тела</p>
                    <p>СЭА — суточная экскреции альбумина</p>
                    <p>Ал/Кр — отношение альбумин/креатинин</p>
                    <p>СЭБ — суточная экскреция белка</p>
                    <p>Об/Кр — отношение общий белок/креатинин</p>
                </div>
            </div>
        </div>
    }

    return <>
        <div className="page-calc page-calc__skf">
            <ResultContainer />

            <div className="category__page">
                <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink>
                <SearchWidget />

                <h1 className="category__title">
                    <NavLink className="category__back-link" to={`/calculators`}></NavLink>
                    <span>Калькулятор СКФ (формула CKD-EPI)<sup>1</sup></span>
                </h1>

            </div>
            
            <div className="calculator__container calculator__container--imt">
                <div className="calculator__body">
                    <div className="calculator__text">Для диагностики и классификации хронической болезни почек (ХБП) у пациентов рекомендуется рассчитывать скорость клубочковой фильтрации (СКФ). Определение СКФ по формуле CKD-EPI дает наиболее приближенные результаты к клиренсовым методам.<sup>2</sup></div>

                    <div className="calculator__title">Формула CKD-EPI (мл/мин/1,73 м2)<sup>1</sup></div>

                    <div className="calculator__radios">
                        <div className="calculator__radios-title">Пол</div>
                        <div className="calculator__radios-checks">
                            <div className="calculator__radios-checks-item">
                                <input type="radio" name="sex" id="sex_f" value="female" onClick={(e) => { setCalcData({
                                    ...calcData,
                                    sex: 'female'
                                })}} />
                                <label htmlFor="sex_f">Женский</label>
                            </div>
                            <div className="calculator__radios-checks-item">
                                <input type="radio" name="sex" id="sex_m" value="male" onClick={(e) => { setCalcData({
                                    ...calcData,
                                    sex: 'male'
                                })}} />
                                <label htmlFor="sex_m">Мужской</label>
                            </div>
                        </div>
                        <div className="calculator__radios-title">Раса</div>
                        <div className="calculator__radios-checks">
                            <div className="calculator__radios-checks-item">
                                <input type="radio" name="race" id="race_any" value="any"  onClick={(e) => { setCalcData({
                                    ...calcData,
                                    race: 'other'
                                })}}/>
                                <label htmlFor="race_any">Другая</label>
                            </div>
                            <div className="calculator__radios-checks-item">
                                <input type="radio" name="race" id="race_negro" value="negro"  onClick={(e) => { setCalcData({
                                    ...calcData,
                                    race: 'negro'
                                })}}/>
                                <label htmlFor="race_negro">Негроидная</label>
                            </div>
                        </div>
                    </div>

                    <div className="calculator__input-container">
                        <div className="calculator__input-content">
                            <div className="input-content__title">Возраст</div>
                            <div className="input-content__bottom">
                                <input type="number" name="age" placeholder="лет" className="input-content__input" value={calcData.age} onChange={(e) => {
                                    setCalcData({
                                        ...calcData,
                                        age: parseInt(e.target.value)
                                    })
                                }}/>
                                <div className="input-content__text">лет</div>
                            </div>
                        </div>

                        <div className="calculator__input-content calculator__input-content--creat">
                            <div className="input-content__title">Креатинин в крови</div>
                            <div className="input-content__bottom">
                                <input type="number" name="createnin" className="input-content__input input-content__input-mkml" value={calcData.createnin} onChange={(e) => {
                                    setCalcData({
                                        ...calcData,
                                        createnin: parseInt(e.target.value)
                                    })
                                }}/>
                                <div className="input-content__text">мкмоль/л</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="calculator__bottom-text">СКФ – скорость клубочковой фильтрации</div>

                    <div className="calculator-body__buttons">
                        <button className="calculator-body__button calculator-body__button--black" onClick={() => {calculateResult()}}>Рассчитать</button>
                        <button className="calculator-body__button calculator-body__button--white" onClick={eraseInput}>Очистить</button>
                    </div>
                </div>
            </div>

            <div className="category__disclaimer">
                <div className="disclaimer__sources">
                    <div className="disclaimer__sources-abbr">
                        <div className="disclaimer__abbr-title">Список сокращений</div>
                        <div className="disclaimer__abbr-item"><b>СКФ</b> – скорость клубочковой фильтрации</div>
                        <div className="disclaimer__abbr-item"><b>СД2</b> – сахарный диабет 2 типа</div>
                        <div className="disclaimer__abbr-item"><b>ХБП</b> – хроническая болезнь почек</div>
                        <div className="disclaimer__abbr-item"><b>HbA1c</b> – гликированный гемоглобин</div>
                        <div className="disclaimer__abbr-item"><b>ДИ</b> – доверительный интервал</div>
                        <div className="disclaimer__abbr-item"><b>ОЧ</b> – отношение частот</div>
                        <div className="disclaimer__abbr-item"><b>МНК</b> – средняя величина, вычисленная методом наименьших квадратов (least square mean)</div>
                        <div className="disclaimer__abbr-item"><b>рСКФ</b> – расчетная скорость клубочковой фильтрации </div>
                        <div className="disclaimer__abbr-item"><b>рСКФ  &lt;60</b> – рСКФ &lt;60 мл/мин/1,73 м2, почечная недостаточность средней или тяжелой степени</div>
                        <div className="disclaimer__abbr-item"><b>ССО</b> – сердечно-сосудистые осложнения</div>
                        <div className="disclaimer__abbr-item"><b>ИМТ</b> – индекс массы тела</div>
                    </div>
                </div>
                <div className="disclaimer__sources">
                    <div className="disclaimer__sources-title">Источники</div>
                    <ol className="disclaimer__sources-list">
                        <li>Levey AS, Stevens LA, Schmid CH, et al. A new equation to estimate glomerular filtration rate [published correction appears in Ann Intern Med. 2011 Sep 20;155(6):408]. Ann Intern Med. 2009;150(9):604-612. doi:10.7326/0003-4819-150-9-200905050-00006.</li>
                        <li>Моисеев В.С. и др. Сердечно-сосудистый риск и хроническая болезнь почек: стратегии кардио-нефропротекции. Российский кардиологический журнал. 2014; (8): 7-37.</li>
                        <li>Хроническая болезнь почек: основные принципы скрининга, диагностики, профилактики и подходы к лечению / А.В. Смирнов, Е.М. Шилов, В.А. Добронравов и др. // Нефрология. 2012. Т. 16. № 1. С. 89–115.</li>
                        <li>Национальные рекомендации. Kidney Disease: Improving Global Outcomes (KDIGO) CKD Work Group. KDIGO 2012. Clinical Practice Guideline for the Evaluation and Management of Chronic Kidney Disease. Kidney Int (Suppl.) 2013; 3: 1-150.</li>
                    </ol>
                </div>
                <div className="disclaimer__description">
                    <div className="disclaimer__description-title">Описание идентификатора</div>
                    <div className="disclaimer__description-text">MAT-RU-2302015-1.0-06.2023.</div>
                </div>
            </div>
        </div>
    </>
}

export default observer(CalcSkf);