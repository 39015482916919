import $api from "../http";



export default class UserService {
    static async checkUserAuth(tg_id: string) {
        return $api.get(`/bot/user/check/${tg_id}`)
    }

    static async writeUserHistory(tg_id: string, categoryName: string, href: string, type: string, parentId: any = 0, itemId: any) {
        return $api.post(`/bot/user/history`, {
            tg_id: tg_id,
            action: categoryName,
            href: href,
            type: type,
            parentId,
            itemId
        })
    }

    static async getMetaByKey(key: any) {
        let response = await $api.get(`/bot/field/${key}`, {
            responseType: 'text',
            withCredentials: false,
            // responseEncoding: 'binary'
        });
        // console.log()

        

        return response.data;
    }
}