import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import categoriesStore from "../store/categoriesStore";
import config from "../config";
import userStore from "../store/userStore";
import pixelWidth from "string-pixel-width";
import SearchWidget from "./SearchWidget";
import CategoriesController from "../controllers/CategoriesController";
import CategoriesService from "../services/categoriesService";
import { CalcListContainer } from "./CalculatorList";


interface Category {
    id: number,
    parent: number,
    title: string,
    image: string,
    isPost: number,
    content: string,
    description: string,
    path: string,
    tags: string,
}

interface CategoryListProps {

}

const CategoryList:FC<any> = ({tgid}: any) => {
    let { id } = useParams();

    const CategoriesStore = new categoriesStore;
    const [category, setCategory] = useState<Category[]>([]);
    const [parent, setParent] = useState<Category>();
    const [mainText, setMainText] = useState('');

    const sendWaLoad = () => {
        userStore.writeUserHistory(tgid, '', '1', 'webapp_loaded');
    }


    const loadText = async () => {
        setMainText(await userStore.getMetaByKey('htmlIndexText'))
    }
                  

    useEffect(() => {

        CategoriesStore.getCategory(id?id:0, tgid).then((result:any) => {
            if (id) CategoriesStore.getCurrentCategory(id, tgid).then((res: any) => {
                setParent(res)
            })

            if (!id || id == '0') {
                loadText();
                setParent(undefined);
            } 

            setCategory(result);

            if (id === undefined && result.length > 0) {
                userStore.writeUserHistory(tgid, '', '1', 'webapp_loaded');
            }
        });
        

    }, [id])

    useEffect(() => {
        CategoriesController.autoSizeText();
    })


    return (
        <ul className="category__list">

            {parent && <NavLink className="category__homelink" to="/0" >Вернуться на главную</NavLink> }

            <SearchWidget />

            { parent &&
                <>
                    <h1 className="category__title">
                        <NavLink className="category__back-link" to={`/${parent.parent}`}></NavLink>
                        {parent.title}
                    </h1>
                    { parent.description && parent.description.length > 3 &&
                        <h2 className="category__description" dangerouslySetInnerHTML={{__html: parent.description}}></h2>
                    }
                </>
            }

            { ( !id || id == '0' ) &&
                <h2 className="category__description category__description--home" dangerouslySetInnerHTML={{__html: mainText}}></h2>
            }

            {(parent && parent.description == '' ) && 
                <div className="category__title-placeholder"></div>
            }

            {category.length>0?category?.map((item, i) => {
                let url = '';
                if (item.isPost == 1) {
                    // Проверяем, это ссылка на доксферу или нет и имееет ли уже какие-то параметры и подставляем токен
                    url = CategoriesController.genTokenUrl(item.content, userStore.token); 
                }

                return (
                    <li key={item.id} >
                        {item.isPost == 0 && 
                            <NavLink className="category__link butt" to={`/${item.id}`} onClick={(e) => CategoriesController.pulseButton(tgid, e)} >
                                { item.image.length > 0 && 
                                    <img src={`${config.API_URL}${item.image}`} className="category__image" />
                                }
                                <span className="category__link-title">{item.title}</span>
                            </NavLink>
                        }

                        {item.isPost == 1 && 
                            <div>
                                <a href={`${url}`} className="category__link category__link--endpoint" target="_blank" onClick={(e) => {
                                    CategoriesController.pulseButton(tgid, e, false, item.title, url, '', item.parent, item.id, item);}}>
                                    { item.image.length > 0 && 
                                        <img src={`${config.API_URL}${item.image}`} className="category__image" />
                                    }
                                    <span className="category__link-title">{item.title}</span>
                                </a>
                                
                                {item.tags != undefined && item.tags != '' && 
                                    <div className="category__tags">
                                        {item.tags.split(',').map((tag, i) => {
                                            tag = tag.trim();
                                            id = id?id:'0';
                                            return (
                                                <NavLink to={`/tag/${id}/${tag}`} key={i} className="tags__item" onClick={(e) => {
                                                    CategoriesController.pulseButton(tgid, e, false, tag, tag, 'tag');
                                                }}>{tag[0] == '#'?tag:'#'+tag} </NavLink>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </li>
                )
            }):false}

            {false &&
                // {/* Переход на калькуляторы */}
                <li key={'calc'}>
                    <NavLink className="category__link butt" to={`/calculators`} onClick={(e) => {
                        if (e.currentTarget.getAttribute('data-click') == 'true') {
                            CategoriesService.writeCustomLog({tgId: tgid, logType: 'gotoCalcs'})
                        }
                        CategoriesController.pulseButton(tgid, e)
                    }} >
                        {/* { item.image.length > 0 && 
                            <img src={`${config.API_URL}${item.image}`} className="category__image" />
                        } */}
                        <span className="category__link-title">Калькуляторы</span>
                    </NavLink>
                </li>
            }

            {/* Вставка кастомных страниц калькуляторов */}
            {parent?.id == 1649 &&
                CalcListContainer({ tgid })
            }
        </ul>
    )
}

export default observer(CategoryList);