import $api from "../http";

export default interface customLogInterface {
    tgId: string, 
    logType: string, 
    itemTitle?: string | undefined, 
    calcData?: string | undefined,
}

export default class CategoriesService {
    static async getCategory (id:any, tgid:any) {
        return $api.post(`/category/${id}`, {tg_id: tgid})
    }

    static async getCategoryInfo(id:any, tg_id: number) {
        return $api.post(`/category/${id}/info`, {tg_id: tg_id}) 
    }

    static async getCategoryByTag(tag:any) {
        return $api.get(`/category/tag/${tag}`) 
    }

    static async searchPosts(tgid: any, searchStr: any) {
        return $api.post(`/search/${searchStr}`, {tg_id: tgid})
    }

    static async writeCustomLog(customLogData: customLogInterface) {
        return $api.post(`/writecustomlog`, customLogData)
    }
}